@media (max-width: 1024px) {
  .stretch-content-1024 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .side-selector {
    position: fixed;
    top: 170px;
    left: -350px;
    z-index: 1002;
    background-color: #fff;
    height: calc(100vh - 180px);
    max-width: 350px;
    box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 43%);
    -webkit-box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 43%);
    -moz-box-shadow: 3px 5px 6px 0px rgba(0, 0, 0, 0.43);
    border: 1px solid #ccc;
    border-left: 0;
    transition: left ease 0.25s;
  }

  .side-selector.open {
    left: 0px;
  }

  .side-selector-arrow {
    display: block !important;
    position: absolute;
    top: 0;
    right: -60px;
    background-color: #fff;
    border-radius: 0;
    background-color: #02a653;
    padding: 10px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 43%);
    -webkit-box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 43%);
    -moz-box-shadow: 3px 5px 6px 0px rgba(0, 0, 0, 0.43);
  }

  .side-selector-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    z-index: 1001;
  }
}
