.navbar-notification .nn-see-all {
  border-top: 1px solid #f1f1f1;
}
.navbar-notification .nn-content {
  max-height: 500px;
  width: 450px;
  overflow-y: auto;
}

.navbar-notification .nn-content > a {
  white-space: unset;
}

.navbar-notification .nn-timestamp {
  color: gray;
  font-size: 13px;
}
