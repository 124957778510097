html,
body {
  background-color: #f0f2f5;
}

.MuiDataGrid-root {
  background-color: #fff;
}

.default-tab-content {
  border: 1px solid #cccc;
  border-top: 0;
  padding: 30px 20px;
  position: relative;
  background-color: #fff;
}

a {
  color: blue;
}

a:hover {
  color: blue;
}

/*override readony background color*/
.form-control[readonly] {
  background-color: #f9fafb;
}

.details-field-set {
  padding: 20px 15px 10px;
  border: 1px dashed #ccc;
  position: relative;
  margin-top: 20px;
}

.details-field-set > legend {
  background-color: #fff;
  position: absolute;
  top: -15px;
  left: 20px;
  width: unset;
  font-size: 1.1rem;
  padding: 0 10px;
}

/*like signature over printed name*/
.text-over-printed-block {
  padding: 15px 15px 0;
  text-align: center;
}

.text-over-printed-block > hr {
  margin: 0;
  border-width: 0;
}

.text-over-printed-block > .text-over-printed-block-value {
  margin-top: -15px;
}

.text-over-printed-block > .text-over-printed-block-label {
  font-weight: bold;
}

.side-selector .side-selector-arrow {
  display: none;
}
.req {
  color: red;
}

/*layout*/
.default-layout-container {
  padding: 15px 0 30px;
}

.default-layout-container .dlc-header-block {
  margin-bottom: 30px;
}

.search-container-block {
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 15px 20px 5px;
}
/*end of layout*/

.side-selector .side-selector-arrow {
  display: none;
}

.white-box {
  background-color: #fff;
  padding: 20px 0 20px;
  box-shadow: 7px 9px 13px -13px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 7px 9px 13px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 9px 13px -13px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
}

/*inner tab*/
ul.default-tab-inner {
  border: 0;
}

ul.default-tab-inner > li > .nav-link {
  border: 0 !important;
  padding: 5px 20px;
}

ul.default-tab-inner > li > .nav-link.active {
  color: #fff;
  font-weight: 600;
  background-color: #00a552;
  padding: 5px 20px;
  border-radius: 50px;
}

ul.default-tab-inner > li > .nav-link:hover {
  color: #000;
}

/* colors */
.color-red {
  color: red;
}

/* buttons */
.btn-info-border {
  background-color: #fff;
  color: #17a2b8;
  border: 1px solid #17a2b8;
  transition: all ease 0.25s;
}

.btn-info-border:hover {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
  opacity: 0.8;
}

.btn-gray {
  background-color: #68717a;
  border-color: #68717a;
  color: #fff;
}

.btn-gray:hover {
  opacity: 0.8;
  background-color: #68717a;
  border-color: #68717a;
  color: #fff;
}

/*select2 disabled*/
.css-1insrsq-control {
  background-color: #fff !important;
}
.css-1insrsq-control .css-lr9fot-singleValue {
  color: #000 !important;
}

/* header label */
.header-label-wrapper {
  padding-top: 50px;
}
.header-label-wrapper .header-label {
  transition: all ease 0.25s;
}

.header-label-wrapper.hlw-hide {
  padding-top: 0;
}
.header-label-wrapper.hlw-hide .header-label {
  top: -50px !important;
}

.header-label-wrapper .header-label-badge {
  display: none;
  position: absolute;
  z-index: 1;
  top: 30px;
  left: 225px;
  cursor: pointer;
}

.header-label-wrapper .header-label-badge:hover {
  opacity: 0.8;
}

.header-label-wrapper.hlw-hide .header-label-badge {
  display: block;
}

/* input group prepend select */
.input-group-prepend-select .input-group-text {
  padding: 0;
  min-width: 140px;
}

.input-group-prepend-select .input-group-text-select {
  width: 100%;
}

.input-group-prepend-select .input-group-text-select > div {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 0;
  min-height: 36px;
}

/* bootstrap modal minimize */
button.btn-minimize {
  position: absolute;
  top: 0;
  right: 3.5rem;
  width: 3.5rem;
  height: 3.5rem;
  margin: 0;
  padding: 0;
  z-index: 10;
  border: 0;
  background-color: transparent;
  color: #b3b3b3;
}

button.btn-minimize:before {
  content: '-';
  font-size: 32px;
}
button.btn-minimize:hover {
  color: #000;
  background-color: transparent;
}

/* bootstrap modal */
.modal-draggable {
  cursor: move;
}

.toast-modal-minimize {
  right: -290px;
  margin-bottom: 10px;
  transition: right ease 0.25s !important;
  background-color: #1a8448;
  color: #fff;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.toast-modal-minimize:hover {
  right: -20px;
}

.toast-modal-minimize svg {
  color: #fff;
  font-size: 20px;
}

.header-label-note {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
}

.header-label-note-img {
  height: 30px;
}

body.under-construction {
  background-image: url('../images/under-construction-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}

body.under-construction .uc-box {
  background-color: #fff;
  max-width: 400px;
  max-height: 350px;
  width: 100%;
  border-radius: 5px;
  height: 100%;
  box-shadow: -1px 1px 11px -2px rgb(0 0 0 / 30%);
  -webkit-box-shadow: -1px 1px 11px -2px rgb(0 0 0 / 30%);
  -moz-box-shadow: -1px 1px 11px -2px rgba(0, 0, 0, 0.3);
  padding: 20px;
  text-align: center;
}

body.under-construction .uc-box img {
  width: 200px;
  opacity: 0.8;
}

body.under-construction .uc-title {
  font-size: 1rem;
  color: gray;
  margin-bottom: 30px;
  margin-top: 40px;
}

body.under-construction .progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}

.modal-form-input .form-control[readonly] {
  background-color: #fff !important;
}

.handsontable .htDimmed {
  color: unset !important;
}

.modal-width-95 {
  min-width: 95%;
}
