body ::-webkit-scrollbar-thumb {
  border-radius: 0;
}

body ::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #c1c1c1;
}

body :hover::-webkit-scrollbar-thumb {
  background: #a8a7a7;
}

body ::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

.dropdown-item-has-submenu:after {
  content: "»";
  transform: unset;
  width: 0;
  height: 0;
  border: 0;
  vertical-align: unset;
  margin-left: 0.255em;
}

@media all and (min-width: 992px) {
  .dropdown-menu li {
    position: relative;
  }
  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
  }
  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }
  .dropdown-menu > li:hover {
    background-color: #f1f1f1;
  }
  .dropdown-menu > li:hover > .submenu {
    display: block;
  }
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-title {
  padding: 0.25rem 1.5rem;
  display: block;
  font-weight: 500;
}

.dropdown-list-item-title {
  background-color: transparent !important;
}

.dropdown-item-indent {
  padding-left: 40px;
}
