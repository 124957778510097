/* navbar */
.topnav {
  overflow: hidden;
  background-color: white;
  border-radius: 15px 20px 0px 0px;
  width: 38%;
}

.topnav a {
  float: left;
  color: black;
  /* background-color: #00a552; */
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #adf7c1;
  /* color: #00a552; */
  color: black;
}

.topnav a.active {
  background-color: #ededed;
  /* color: #00a552; */
  color: black;
}

/*panel */
.div-center {
  margin: auto;
  width: 100%;
  padding: 10px;
}
.div-panel {
  /* border-style: solid;
    color:#00a552; */
  border-radius: 0px 15px 15px 15px;
  border-top: none;
  background-color: white;
  padding: 20px;
  padding-left: 40px;
}

.ending_balance {
  background-color: #28a745 !important;
}

.beg_balance {
  background-color: #79fcd0 !important;
}

.grand-total-text {
  color: red;
  font-size: small;
  font-style: bold;
}
