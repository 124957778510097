.dropdown-account-officer-add-button {
  background-color: #e9ecef !important;
  border-color: #ced4da !important;
}

.dropdown-account-officer-add-button:hover {
  opacity: 0.8;
  background-color: #ccc !important;
  border-color: #ccc !important;
}

.dropdown-account-officer-add-icon {
  color: #7f8285;
  margin-top: -3px;
}

.dropdown-account-officer-select {
  padding: 0 !important;
  border: 0 !important;
}

.dropdown-account-officer-select___control {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
