.mfup-container {
  border: 1px solid #ced4da;
  border-radius: 5px;
  overflow: hidden;
}

.mfup-container > .mfup-preview {
  min-height: 150px;
}

.mfup-container > .mfup-preview > .mfup-preview-file {
  padding: 10px 50px 10px 10px;
  border-bottom: 1px solid #f1f1f1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.mfup-container > .mfup-preview > .mfup-preview-file:last-child {
  border-bottom: 0;
}

.mfup-container > .mfup-preview > .mfup-preview-file:hover {
  background-color: #f1f1f1;
}

.mfup-container > input[type="file"] {
  border-radius: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}

.mfup-remove-file {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 22px;
  color: red;
  cursor: pointer;
  opacity: 0.5;
}

.mfup-remove-file:hover {
  opacity: 1;
}
