.modal-width {
  min-width: 90%;
}

.modal-width-xl {
  min-width: 95%;
}

.tab-height {
  min-height: 70vh;
}

.modal-center {
  top: 20%;
}

.modal-md {
  min-width: 50%;
}

.modal-width-lg {
  min-width: 80%;
}
