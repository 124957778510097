#forecast-hot-container {
  background-color: #fff;
  width: 100%;
  height: 65vh;
}

#forecast-hot-container .table-year-row {
  background-color: #808080;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

#forecast-hot-container .table-quarter-row {
  background-color: #b7b7b7;
  color: #fff;
  text-align: center;
  font-weight: 500;
}

#forecast-hot-container .table-month-row {
  background-color: #000;
  color: #e1e1e1;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}

#forecast-hot-container .table-hit-rate-row {
  color: red;
  font-weight: 500;
  text-decoration: underline;
}

#forecast-hot-container .table-current-inventory-row {
  background-color: #808080;
  color: #fff;
  font-weight: 500;
}

#forecast-hot-container .table-projected-inventory-row {
  background-color: #b7b7b7;
  color: #000;
  font-weight: 500;
}

#forecast-hot-container .table-text-black {
  color: #000;
}

#forecast-hot-container .table-inventory-level-row-red {
  background-color: #fe0000;
  color: #fff;
}

#forecast-hot-container .table-inventory-level-row-yellow {
  background-color: #fafb00;
  color: #000;
}

#forecast-hot-container .table-inventory-level-row-light-green {
  background-color: #70ac48;
  color: #fff;
}

#forecast-hot-container .table-inventory-level-row-dark-green {
  background-color: #385624;
  color: #fff;
}

#forecast-hot-container .table-inventory-level-row-gray {
  background-color: #f0f0f0;
  color: #000;
  font-weight: 500;
}
#forecast-hot-container .table-current-month-year-column {
  position: relative;
}

#forecast-hot-container .table-current-month-year-column:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 94, 255, 0.04);
}

#forecast-hot-container .table-actual-sales-projected {
  color: gray;
  font-weight: 500;
  font-style: italic;
}

#forecast-hot-container .table-editable-row {
  background-color: rgb(221, 251, 217);
}

#forecast-hot-container .table-incoming-po-label {
  color: blue !important;
  cursor: pointer;
}

#forecast-hot-container .table-incoming-po-label:hover {
  text-decoration: underline;
}

/* #forecast-hot-container
  .handsontable
  table.htCore
  > tbody
  > tr:first-child
  > th,
#forecast-hot-container
  .handsontable
  table.htCore
  > tbody
  > tr:nth-child(2)
  > th,
#forecast-hot-container
  .handsontable
  table.htCore
  > tbody
  > tr:nth-child(3)
  > th {
  background: #fff !important;
  border-color: #fff;
}

#forecast-hot-container
  .handsontable
  table.htCore
  > tbody
  > tr:nth-child(4)
  > th {
  border-top: 1px solid #ccc;
}

#forecast-hot-container
  .handsontable
  table.htCore
  > tbody
  > tr:first-child
  > td:first-child {
  border-color: #000;
}

#forecast-hot-container
  .handsontable
  table.htCore
  > tbody
  > tr:first-child
  > td:nth-child(2) {
  border-color: #fff !important;
}

#forecast-hot-container
  .handsontable
  table.htCore
  > tbody
  > tr:nth-child(4)
  > td:nth-child(2) {
  border-top: 1px solid #ccc;
} */

.input-group-forecast-stack > .input-group-text {
  display: block;
  width: 100%;
  border-radius: 0;
  margin: 0 !important;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px !important;
  border-bottom: 0;
}

.input-group-forecast-stack > input.form-control {
  border-radius: 0 !important;
  margin: 0 !important;
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}
