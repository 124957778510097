.MuiDataGrid-row:nth-child(even) {
  background-color: #f1f1f1;
}

/* .MuiDataGrid-main > div:first-child {
  z-index: 1;
} */

.MuiTablePagination-root p {
  margin-bottom: 0;
}

.btn-action-buttons > .btn-link > svg {
  font-size: 12px !important;
  color: #222;
  width: 17px;
  height: 17px;
}

.btn-action-buttons > .btn-link {
  padding: 3px 2px;
  transition: all ease 1s;
}

.btn-action-buttons > .btn-link:hover {
  background-color: #e1e1e1;
}

.btn-action-buttons .btn-action + .btn-action {
  margin-left: 7px;
}

.btn-add-create > svg {
  margin-top: -4px;
}

.row-no-account-config {
  background-color: #f8d7da !important;
  color: #721c24;
}
