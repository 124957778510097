.primary-date-picker .react-datepicker__month-dropdown-container--scroll,
.primary-date-picker .react-datepicker__month-dropdown-container--select,
.primary-date-picker .react-datepicker__month-year-dropdown-container--scroll,
.primary-date-picker .react-datepicker__month-year-dropdown-container--select,
.primary-date-picker .react-datepicker__year-dropdown-container--scroll,
.primary-date-picker .react-datepicker__year-dropdown-container--select {
  display: inline-block;
  margin: 0 2px;
  margin-right: 20px;
}

.primary-date-picker .react-datepicker-popper {
  z-index: 5;
}
