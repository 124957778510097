.accounts-row {
  background-color: white !important;
}

.lineitem-row {
  background-color: lightblue !important;
}

.sublineitem-row {
  background-color: rgb(171, 245, 171) !important;
}

.subgroup-row {
  background-color: rgb(245, 172, 89) !important;
}

.group-row {
  background-color: rgb(219, 230, 78) !important;
}

.type-row {
  background-color: rgb(243, 105, 105) !important;
}
