.material-icons {
  font-size: 40px;
  margin-top: 5px;
}

.currencies {
  font-size: 26px;
}

.currencies-label {
  font-size: 15px;
  font-weight: 500;
}

.custom {
  color: white;
}

.rateRed {
  background-color: #ff0000 !important;
  color: white;
  font-size: 20px;
  font-weight: bold;
  box-shadow: unset !important;
}

.rateYellow {
  background-color: #ffff00 !important;
  color: white;
  font-size: 20px;
  font-weight: bold;
  box-shadow: unset !important;
}

.rateOrange {
  background-color: #ff9900 !important;
  color: white;
  font-size: 20px;
  font-weight: bold;
  box-shadow: unset !important;
}

.rateGreen {
  background-color: #6aa84f !important;
  color: white;
  font-size: 20px;
  font-weight: bold;
  box-shadow: unset !important;
}
.rateSuperGreen {
  background-color: #00ff00 !important;
  font-size: 20px;
  color: white;
  font-weight: bold;
  box-shadow: unset !important;
}

.assessment-text {
  color: black;
  font-weight: bold;
  font-size: 50px;
}
.borders {
  border: 1px solid black !important;
}

.requiredField {
  color: red;
}

.ratingText {
  color: white;
  font-size: 55px;
  display: center;
}
